<template>
  <div class="d-flex align-items-start">
    <context-menu ref="context">
      <b-button v-for="(menu, index) in contextMenus" :key="index" block
        class="text-left mb-0 px-3 d-flex align-items-center"
        :variant="selectedMenu.url === menu.url ? 'primary' : 'light'" @click="handleSelectMenu(menu)">
        <b-icon class="mr-2" :icon="menu.icon" />
        {{ menu.name }}
      </b-button>
    </context-menu>
    <b-card class="w-100 min-h-62 mobile-transparent">
      <router-view />
    </b-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedMenu: {},
      contextMenus: [
        {
          name: "Certidão de Distribuição de Ações Trabalhistas",
          url: "/my-enterprise/legal/labor-lawsuits/list",
          icon: "patch-check",
        },
        {
          name: "Seguros Cobertura de Riscos vigentes na empresa",
          url: "/my-enterprise/legal/insurance",
          icon: "shield-check",
        },
      ],
    };
  },
  methods: {
    handleSelectMenu(menu) {
      if (this.selectedMenu.url === menu.url) {
        this.$refs.context.close();
        return;
      }

      this.$refs.context.close();
      this.selectedMenu = menu;
      this.$router.push(menu.url);
    },
  },
  created() {
    this.selectedMenu.url = this.$route.fullPath;
  },
};
</script>
